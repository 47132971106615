import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setDateFormat } from "./appSlice";
import { getRequest } from "utils/APIRequest";

export const getUserFromToken = createAsyncThunk(
  "auth/getUserFromToken",
  async (_, { dispatch }) => {
    const response = await getRequest(`/api/users/info`);

    if (!response.error) {
      dispatch(setUser(response));
      dispatch(setDateFormat(response.utc_data));
      if (!response.roles || !response.roles.length) {
        return response;
      }
    } else {
      dispatch(setUser({}));
    }
  }
);

const initialState = {
  token: localStorage.getItem("refresh_token"),
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateLayoutConfig: (state, action) => {
      const { data } = action.payload;
      const user = state.user;
      user.roles[0].users_roles.layout_config = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserFromToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserFromToken.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getUserFromToken.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setToken, setUser, updateLayoutConfig } = authSlice.actions;
export default authSlice.reducer;
