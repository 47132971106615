const scheduleColor = "0E161E";
const estimatedColor = "3786F9";
const targetColor = "00BC7D";
const actualColor = "FF0000";

export default [
  {
    title: "Aircraft Reg",
    isDisabled: true,
    name: "aircraft_registration",
    icon: "airplanemode_active",
    activeField: true,
    type: "string",
    accuracy: [
      {
        title: "Aircraft Reg",
        name: "aircraft_registration",
      },
    ],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "Aircraft Type",
    isDisabled: true,
    name: "aircraft_type",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [{ title: "Aircraft Type", name: "aircraft_type" }],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "Arrival Runway",
    isDisabled: true,
    name: "arrival_runway",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [
      {
        title: "Arrival Runway",
        name: "arrival_runway",
        symbol: "A",
      },
      {
        title: "Planning Runway",
        name: "planing_runway",
        symbol: "P",
      },
    ],
    list: ["arrival"],
  },
  {
    title: "Departure Runway",
    isDisabled: true,
    name: "departure_runway",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [
      {
        title: "Departure Runway",
        name: "departure_runway",
        symbol: "A",
      },
      {
        title: "Planning Runway",
        name: "planing_runway",
        symbol: "P",
      },
    ],
    list: ["departure"],
  },
  {
    title: "Status",
    isDisabled: true,
    name: "status",
    icon: "airplanemode_active",
    activeField: true,
    type: "string",
    accuracy: [{ title: "Flight Status", name: "status" }],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "Flight Type",
    isDisabled: true,
    name: "flight_type",
    icon: "plane",
    activeField: false,
    type: "string",
    accuracy: [{ title: "Flight Type", name: "flight_type" }],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "ICAO",
    isDisabled: true,
    name: "arrival_icao",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [{ title: "ICAO", name: "arrival_icao" }],
    list: ["arrival"],
  },
  {
    title: "ICAO",
    isDisabled: true,
    name: "departure_icao",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [{ title: "ICAO", name: "departure_icao" }],
    list: ["departure", "turnaround"],
  },
  {
    title: "IATA",
    isDisabled: true,
    name: "arrival_iata",
    icon: "plane",
    type: "string",
    activeField: true,
    accuracy: [{ title: "IATA", name: "arrival_iata" }],
    list: ["arrival"],
  },
  {
    title: "IATA",
    isDisabled: true,
    name: "departure_iata",
    icon: "plane",
    type: "string",
    activeField: true,
    accuracy: [{ title: "IATA", name: "departure_iata" }],
    list: ["departure", "turnaround"],
  },
  {
    title: "GH",
    isDisabled: true,
    name: "ground_handling",
    icon: "plane",
    activeField: true,
    type: "string",
    accuracy: [{ title: "GH", name: "ground_handling" }],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "Stand Group",
    isDisabled: true,
    name: "stand_group",
    icon: "map-marker",
    activeField: false,
    type: "string",
    accuracy: [
      {
        title: "Stand-G",
        name: "stand_group",
        symbol: "A",
      },
      {
        title: "Planning Stand -G",
        name: "planing_stand_group",
        symbol: "P",
      },
    ],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "Stand",
    isDisabled: true,
    name: "parking_stand",
    icon: "map-marker",
    activeField: true,
    type: "string",
    accuracy: [
      {
        title: "Stand",
        name: "parking_stand",
        symbol: "A",
      },
      {
        title: "Planning Stand",
        name: "planing_stand",
        symbol: "P",
      },
    ],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "ADEP",
    isDisabled: true,
    name: "adep",
    icon: "map-marker",
    activeField: true,
    type: "string",
    accuracy: [{ title: "ADEP", name: "adep" }],
    list: ["arrival"],
  },
  {
    title: "ADES",
    isDisabled: true,
    name: "ades",
    icon: "map-marker",
    activeField: true,
    type: "string",
    accuracy: [{ title: "ADES", name: "ades" }],
    list: ["departure", "turnaround"],
  },
  {
    title: "Gate",
    isDisabled: true,
    name: "boarding_gate",
    activeField: true,
    type: "string",
    accuracy: [
      {
        title: "B-Gate",
        name: "boarding_gate",
        symbol: "A",
      },
      {
        title: "Planning Gate",
        name: "planing_gate",
        symbol: "P",
      },
    ],
    list: ["arrival", "departure", "turnaround"],
  },
  {
    title: "LDT",
    isDisabled: false,
    name: "ldt",
    icon: "clock-o",
    type: "date",
    editableFields: ["aldt"],
    activeField: true,
    accuracy: [
      {
        title: "ALDT",
        name: "aldt",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "ELDT",
        name: "eldt",
        color: estimatedColor,
        symbol: "E",
      },
    ],
    list: ["arrival"],
  },
  {
    title: "XIT",
    isDisabled: false,
    editableFields: ["exit"],
    name: "xit",
    icon: "clock-o",
    type: "number",
    activeField: true,
    accuracy: [
      {
        title: "AXIT",
        name: "axit",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "EXIT",
        name: "exit",
        color: estimatedColor,
        symbol: "E",
      },
    ],
    list: ["arrival"],
  },
  {
    title: "IBT",
    isDisabled: false,
    name: "ibt",
    icon: "clock-o",
    editableFields: ["aibt", "eibt"],
    type: "date",
    activeField: true,
    accuracy: [
      {
        title: "AIBT",
        name: "aibt",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "EIBT",
        name: "eibt",
        color: estimatedColor,
        symbol: "E",
      },
      {
        title: "SIBT",
        name: "sibt",
        color: scheduleColor,
        symbol: "S",
      },
    ],
    list: ["arrival", "turnaround"],
  },
  {
    title: "CGT",
    isDisabled: false,
    name: "cgt",
    icon: "clock-o",
    editableFields: ["acgt"],
    type: "date",
    activeField: true,
    accuracy: [
      {
        title: "ACGT",
        name: "acgt",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "ECGT",
        name: "ecgt",
        color: estimatedColor,
        symbol: "E",
      },
    ],
    list: ["turnaround", "arrival"],
  },
  {
    title: "ONGT",
    isDisabled: true,
    name: "ongt",
    icon: "clock-o",
    type: "string",
    needReformat: true,
    activeField: true,
    accuracy: [
      {
        title: "ONGT",
        name: "ongt",
        symbol: "O",
      },
    ],
    list: ["turnaround"],
  },
  // Actual Start Boarding Time
  {
    title: "ASBT",
    isDisabled: false,
    name: "asbt",
    icon: "clock-o",
    editableFields: ["asbt"],
    type: "date",
    activeField: false,
    accuracy: [{ title: "ASBT", name: "asbt", color: actualColor }],
    list: ["turnaround"],
  },
  {
    title: "TTT",
    isDisabled: false,
    editableFields: ["ettt"],
    name: "ttt",
    icon: "clock-o",
    activeField: true,
    type: "number",
    accuracy: [
      {
        title: "ATTT",
        name: "attt",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "ETTT",
        name: "ettt",
        color: estimatedColor,
        symbol: "E",
      },
      {
        title: "MTTT",
        name: "mttt",
        color: estimatedColor,
        symbol: "M",
      },
    ],
    list: ["turnaround"],
  },
  // Actual End of Ground handling Time
  {
    title: "AEGT",
    isDisabled: false,
    name: "aegt",
    editableFields: ["aegt"],
    icon: "clock-o",
    type: "date",
    activeField: false,
    accuracy: [{ title: "AEGT", name: "aegt", color: actualColor }],
    list: ["departure", "turnaround"],
  },
  {
    title: "OBT",
    isDisabled: false,
    editableFields: ["aobt", "tobt", "eobt"],
    name: "obt",
    icon: "clock-o",
    type: "date",
    activeField: true,
    accuracy: [
      {
        title: "AOBT",
        name: "aobt",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "TOBT",
        name: "tobt",
        color: targetColor,
        symbol: "T",
      },
      {
        title: "EOBT",
        name: "eobt",
        color: estimatedColor,
        symbol: "E",
      },
      {
        title: "SOBT",
        name: "sobt",
        color: scheduleColor,
        symbol: "S",
      },
    ],
    list: ["departure", "turnaround"],
  },
  {
    title: "ARDT",
    isDisabled: false,
    name: "ardt",
    icon: "clock-o",
    type: "date",
    editableFields: ["ardt"],
    activeField: true,
    accuracy: [{ title: "ARDT", name: "ardt", color: actualColor }],
    list: ["departure"],
  },
  // Actual Start Up Request Time
  {
    title: "ASRT",
    isDisabled: false,
    name: "asrt",
    icon: "clock-o",
    editableFields: ["asrt"],
    type: "date",
    activeField: false,
    accuracy: [{ title: "asrt", name: "asrt", color: actualColor }],
    list: ["departure"],
  },
  {
    title: "SAT",
    isDisabled: false,
    editableFields: ["asat", "tsat"],
    name: "sat",
    icon: "clock-o",
    type: "date",
    activeField: true,
    accuracy: [
      {
        title: "ASAT",
        name: "asat",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "TSAT",
        name: "tsat",
        color: targetColor,
        symbol: "T",
      },
    ],
    list: ["departure", "turnaround"],
  },
  {
    title: "XOT",
    isDisabled: false,
    name: "xot",
    editableFields: ["exot"],
    icon: "clock-o",
    activeField: true,
    type: "number",
    accuracy: [
      {
        title: "AXOT",
        name: "axot",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "EXOT",
        name: "exot",
        color: estimatedColor,
        symbol: "E",
      },
    ],
    list: ["departure"],
  },
  {
    title: "TOT",
    isDisabled: false,
    name: "tot",
    editableFields: ["atot"],
    icon: "clock-o",
    type: "date",
    activeField: true,
    accuracy: [
      {
        title: "ATOT",
        name: "atot",
        color: actualColor,
        symbol: "A",
      },
      {
        title: "TTOT",
        name: "ttot",
        color: targetColor,
        symbol: "T",
      },
      {
        title: "ETOT",
        name: "etot",
        color: estimatedColor,
        symbol: "E",
      },
    ],
    list: ["departure"],
  },
  {
    title: "CTOT",
    isDisabled: true,
    name: "ctot",
    icon: "clock-o",
    type: "date",
    activeField: true,
    accuracy: [{ title: "CTOT", name: "ctot", color: estimatedColor }],
    list: ["departure"],
  },
  {
    title: "REGULATED",
    isDisabled: true,
    name: "regulated",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "REGULATED", name: "regulated" }],
    list: ["departure"],
  },
  {
    title: "Alerts",
    isDisabled: true,
    name: "alerts",
    icon: "exclamation-triangle",
    type: "number",
    activeField: true,
    accuracy: [{ title: "Alerts", name: "alerts" }],
    list: ["arrival", "departure", "turnaround"],
  },
  // Standard Arrival Route: assigned by ATC.
  {
    title: "STAR",
    isDisabled: true,
    name: "star",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "STAR", name: "star" }],
    list: ["arrival", "turnaround"],
  },
  // Standard instrumental Departure assigned to the flight by ATC.
  {
    title: "SID",
    isDisabled: true,
    name: "sid",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "SID", name: "sid" }],
    list: ["departure"],
  },
  // LINK FLIGHT
  {
    title: "Linked Flight",
    isDisabled: true,
    name: "arrival_iata",
    icon: "clock-o",
    type: "string",
    activeField: true,
    accuracy: [{ title: "Linked Flight", name: "arrival_iata" }],
    list: ["departure", "turnaround"],
  },

  {
    title: "Linked Flight",
    isDisabled: true,
    name: "departure_iata",
    icon: "clock-o",
    type: "string",
    activeField: true,
    accuracy: [{ title: "Linked Flight", name: "departure_iata" }],
    list: ["arrival"],
  },
  {
    title: "TERMINAL",
    isDisabled: true,
    name: "terminal",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "TERMINAL", name: "terminal" }],
    list: ["arrival", "turnaround", "departure"],
  },
  {
    title: "RAMP/TEAM",
    isDisabled: true,
    name: "ramp_team",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "RAMP/TEAM", name: "ramp_team" }],
    list: ["arrival", "departure"],
  },
  {
    title: "PAX",
    isDisabled: true,
    name: "pax",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "PAX", name: "pax" }],
    list: ["arrival", "turnaround", "departure"],
  },
  {
    title: "PRM",
    isDisabled: true,
    name: "prm",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "PRM", name: "prm" }],
    list: ["arrival", "departure"],
  },
  {
    title: "DEBOARDING",
    isDisabled: true,
    name: "deboarding",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "DEBOARDING", name: "deboarding" }],
    list: ["turnaround"],
  },
  {
    title: "BOARDING",
    isDisabled: true,
    name: "boarding",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "BOARDING", name: "boarding" }],
    list: ["turnaround"],
  },
  {
    title: "REDCAP",
    isDisabled: true,
    name: "redcap",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "REDCAP", name: "REDCAP" }],
    list: ["arrival", "departure"],
  },
  {
    title: "SERVICES",
    isDisabled: true,
    name: "services",
    icon: "clock-o",
    type: "string",
    activeField: false,
    accuracy: [{ title: "SERVICES", name: "services" }],
    list: ["turnaround", "departure"],
  },
];
