import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/APIRequest";
import { successMessage } from "./toastAlertSlice";

const initialState = {
  organizations: [],
  roles: [],
  airlines: [],
  loading: false,
  total: 0,
  organization: {},
  users: [],
};

export const fetchOrganizations = createAsyncThunk(
  "organizations/fetchOrganizations",
  async (data) => {
    const response = await getRequest(`/api/organizations`, data);
    return response;
  }
);

export const deleteOrganization = createAsyncThunk(
  "organizations/deleteOrganization",
  async (ids, { dispatch }) => {
    const url = `/api/organizations`;
    const response = await deleteRequest(url, { ids });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Delete organization successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreOrganization = createAsyncThunk(
  "organizations/restoreOrganization",
  async (ids, { dispatch }) => {
    const url = `/api/organizations/restore/multi`;
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore organization successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreUsersOrganization = createAsyncThunk(
  "organizations/restoreUsersOrganization",
  async (ids, { dispatch }) => {
    const url = `/api/organizations/restore/userOrganization`;
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore user successfully",
          back: true,
        })
      );
    }
  }
);

export const getRolesByOrgId = createAsyncThunk(
  "organizations/getRolesByOrgId",
  async (orgId) => {
    const url = `/api/organizations/${orgId}/roles`;
    const response = await getRequest(url);
    return response;
  }
);

export const getAirlinesByOrgId = createAsyncThunk(
  "organizations/getAirlinesByOrgId",
  async (orgId) => {
    const url = `/api/organizations/${orgId}/airlines`;
    const response = await getRequest(url);
    if (!response.error) {
      return response;
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "organizations/fetchOrganization",
  async (dataId) => {
    const url = `/api/organizations/${dataId}`;
    const response = await getRequest(url);

    if (!response.error) {
      return response;
    }
  }
);

export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (data, { dispatch }) => {
    const response = await postRequest(
      `/api/organizations/`,
      data,
      { Cache: "no-cache" },
      true
    );
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Create organization successfully",
          back: true,
        })
      );
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "organizations/updateOrganization",
  async ({ organization_id, data }, { dispatch }) => {
    const response = await putRequest(
      `/api/organizations/${organization_id}`,
      data,
      { Cache: "no-cache" },
      true
    );
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Update organization successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    reinitOrganizations() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchOrganizations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteOrganization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(restoreOrganization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreUsersOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreUsersOrganization.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(restoreUsersOrganization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRolesByOrgId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRolesByOrgId.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(getRolesByOrgId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAirlinesByOrgId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAirlinesByOrgId.fulfilled, (state, action) => {
        state.loading = false;
        state.airlines = action.payload;
      })
      .addCase(getAirlinesByOrgId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.loading = false;
        const rolesIds = action.payload.roles.map((role) => role.role);
        state.organization = { ...action.payload, rolesIds };
      })
      .addCase(fetchOrganization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrganization.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createOrganization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrganization.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitOrganizations } = organizationSlice.actions;
export default organizationSlice.reducer;
