import React from "react";
import UserGrid from "./user_grid";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "features/userSlice";

function NavProfileMenu(props) {
  const ref = useRef();
  const dispatch = useDispatch();

  const { info } = useSelector((state) => state.UserInfo);

  useEffect(() => {
    dispatch(fetchUserProfile());
    return () => { };
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        props.displayUserGrid &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        props.showUserGrid(e);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.displayUserGrid]);

  return (
    <div ref={ref}>
      <img
        className="user-image user-image-nav"
        alt=""
        src={
          info.thumbnail
            ? `${process.env.REACT_APP_ASSETS_PATH}${info.thumbnail}`
            : `${process.env.REACT_APP_USER_DEFAULT_PROFILE_PICTURE}`
        }
        onError={(ev) =>
          (ev.target.src = `${process.env.REACT_APP_USER_DEFAULT_PROFILE_PICTURE}`)
        }
        onClick={props.showUserGrid}
        onKeyDown={props.showUserGrid}
      />
      {props.auth?.token ? (
        <UserGrid
          user={props.auth.user}
          display={props.display}
          handleUserSelection={props.handleUserSelection}
          auth={props.auth}
        />
      ) : null}
    </div>
  );
}

export default NavProfileMenu;
