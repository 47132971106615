import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/APIRequest";

const initialState = {
  messages: [],
  flights: [],
  groups: [],
  filterChannels: [],
  selectedChannel: {},
  searching: false,
  unreadMessage: {},
  members: [],
  loading: false,
  total: 0,
};

export const fetchChannels = createAsyncThunk(
  "messages/fetchChannels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`/messaging/all`, {
				method: "GET",
        headers: {
					"Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch channels");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/chats", data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest(
        "/api/chats",
        data,
        {
          Accept: "application/json",
        },
        true
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createChannel = createAsyncThunk(
  "messages/createChannel",
  async (data, { dispatch }) => {
    const formData = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(`${key}[]`, item.toString()));
      } else {
        formData.append(key, value.toString());
      }
    });

    const response = await fetch(`/messaging/group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Cache: "no-cache",
      },
      body: formData,
    });

    if (response.status === 200 || response.status === 201) {
      return await response.json();
    } else if (response.status === 204) {
      return true;
    } else {
      const data = await response?.json();
      return { error: true, message: data?.message };
    }
  }
);

export const getRoomMembers = createAsyncThunk(
  "messages/getRoomMembers",
	async (roomId, { rejectWithValue }) => {
    try {
      const response = await fetch(`/messaging/group/members?id=${roomId}`, {
				method: "GET",
        headers: {
					"Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch channels");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUnReadMessages = createAsyncThunk(
  "messages/fetchUnReadMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getRequest("/api/chats/unread", data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const readMessages = createAsyncThunk(
  "messages/readMessages",
  async (channelId, { rejectWithValue }) => {
    try {
      await postRequest("/api/chats/read", { channel_id: channelId });
      return channelId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGroupChannel = createAsyncThunk(
  "messages/deleteGroupChannel",
  async (channelId, { rejectWithValue }) => {
    try {
      const response = await deleteRequest("/api/channels", { channelId });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateChannel = createAsyncThunk(
  "messages/updateChannel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await putRequest("/api/channels", JSON.stringify(data));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.loading = false;
        const { flightChannels, groups } = action.payload || {};
        state.flights = flightChannels || [];
        state.groups = groups || [];
      })
      .addCase(fetchChannels.rejected, (state) => {
        state.loading = false;
      })
			.addCase(getRoomMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoomMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload || [];
      })
      .addCase(getRoomMembers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.messages.push(action.payload);
        }
      })
      .addCase(sendMessage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createChannel.fulfilled, (state, action) => {
        state.loading = false;
        if (
          action.payload &&
          !state.channels.some((channel) => channel.id === action.payload.id)
        ) {
          state.channels.unshift(action.payload);
        }
      })
      .addCase(createChannel.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUnReadMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.unreadMessage = action.payload;
      })
      .addCase(fetchUnReadMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(readMessages.fulfilled, (state, action) => {
        state.loading = false;
        delete state.unreadMessage[action.payload];
      })
      .addCase(readMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteGroupChannel.fulfilled, (state, action) => {
        state.loading = false;
        state.channels = state.channels.filter(
          (channel) => channel.id !== action.payload
        );
      })
      .addCase(deleteGroupChannel.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.channels.findIndex(
          (channel) => channel.id === action.payload.id
        );
        if (index !== -1) {
          state.channels[index] = action.payload;
        }
        if (state.selectedChannel.id === action.payload.id) {
          state.selectedChannel = action.payload;
        }
      })
      .addCase(updateChannel.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default messagesSlice.reducer;
