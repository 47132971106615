import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
  back: false,
};

const toastAlertSlice = createSlice({
  name: "toastAlert",
  initialState,
  reducers: {
    reinitToastAlert: (state) => {
      return initialState;
    },
    successMessage: (state, action) => {
      state.type = "success";
      state.message = action.payload.message;
      state.back = action.payload.back || false;
    },
    errorMessage: (state, action) => {
      state.type = "error";
      state.message = action.payload.message;
    },
  },
});

export const { reinitToastAlert, successMessage, errorMessage } =
  toastAlertSlice.actions;
export default toastAlertSlice.reducer;
