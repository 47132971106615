import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateFlight, deActivateFlight } from "features/appSlice";
import { actions as toolTipActions } from "redux-tooltip";
import Fields from "./Fields";
import { isEmpty } from "lodash";

const TableRow = ({
  flight,
  headers,
  selectedFlight,
  onClickFieldHandler,
  clearData,
  setX,
  setY,
}) => {
  const dispatch = useDispatch();
  const { activeFlight } = useSelector((state) => state.general);

  const getClassName = () => {
    if (
      (activeFlight?.flight_number === flight.flight_number &&
        activeFlight?.flight_date === flight.flight_date) ||
      (selectedFlight.flight_number === flight.flight_number &&
        selectedFlight?.departure_flight_date === flight.departure_flight_date)
    )
      return "activeFlight";
    return null;
  };

  const handleActiveFlight = (ev) => {
    if (ev.keyCode && ev.keyCode !== 13) {
      return null;
    }

    const { flight_date, flight_number } = flight;

    if (!isEmpty(activeFlight) && activeFlight?.flight_date === flight_date) {
      dispatch(deActivateFlight({ flight_date, flight_number }));
    } else {
      dispatch(activateFlight({ flight_date, flight_number, flight }));
    }
  };

  const getFlightReference = () => {
    return `${flight.flight_number}/${
      flight.departure_flight_date || flight.flight_date
    }`;
  };

  return (
    <tr key={getFlightReference()} className={getClassName()}>
      <td>
        <div>
          <input
            name={getFlightReference()}
            checked={
              (activeFlight.flight_date === flight.flight_date ||
                activeFlight.departure_flight_date ===
                  flight.departure_flight_date) &&
              activeFlight.flight_number === flight.flight_number
            }
            readOnly
            type="radio"
            aria-label={`Activate ${getFlightReference()}`}
            className="acdm-input-radio-select"
            id={`${getFlightReference()}`}
          />
          <label
            onClick={handleActiveFlight}
            onKeyDown={handleActiveFlight}
            tabIndex="0"
            htmlFor={`${getFlightReference()}`}
          />
        </div>
      </td>
      <Fields
        flight={flight}
        headers={headers}
        onClickFieldHandler={onClickFieldHandler}
        clearData={clearData}
        setY={setY}
        setX={setX}
      />
    </tr>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.flight, nextProps.flight) &&
    prevProps.headers === nextProps.headers &&
    prevProps.activeFlight === nextProps.activeFlight &&
    prevProps?.selectedFlight?.aircraft_registration ===
      nextProps?.selectedFlight?.aircraft_registration
  );
};
export default React.memo(TableRow, areEqual);
