import React, { memo } from "react";
import "./style.css";

const Tooltip = memo(({ data, Compo, classConfig }) => {
  return (
    <span className="toolTip">
      {data}

      <span className={classConfig ? classConfig : "tooltiptext"}>{Compo}</span>
    </span>
  );
});

export default Tooltip;
