import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "utils/APIRequest";

export const fetchManualUpdatesLogs = createAsyncThunk(
  "notificationLogs/fetchManualUpdatesLogs",
  async (data) => {
    const response = await getRequest("/api/manualUpdatesLogs", data);
    return response;
  }
);

const initialState = {
  manualUpdateLogs: [],
  loading: false,
  total: 0,
};

const notificationLogsSlice = createSlice({
  name: "notificationLogs",
  initialState,
  reducers: {
    reinitManualUpdatesLogs: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManualUpdatesLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchManualUpdatesLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.manualUpdateLogs = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchManualUpdatesLogs.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitManualUpdatesLogs } = notificationLogsSlice.actions;

export default notificationLogsSlice.reducer;
