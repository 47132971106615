export const vdgsCodes = {
  "Ready/Active and Prepared": "#00BC7D",
  Error: "#C32638",
  Parked: "#8442F3",
  "Docking and Blocks on ": "#205CF6",
  "Scheduled departures and Blocks off": "#FCC53A",
  Other: "#607099",
};

export const acdmCodes = {
  SCH: "#0e161e",
  EST: "#3786f929",
  TAR: "#00bc7d29",
  ACT: "#ff000029",
};

export const statusColors = {
  active: "#00BC7D",
  error: "#C32638",
  ready: "#00BC7D",
  prepared: "#00BC7D",
  parked: "#8442F3",
  docking: "#205CF6",
  "blocks on": "#205CF6",
  "scheduled departure": "#FCC53A",
  "blocks off event": "#FCC53A",
};

export const PDS_states = [
  {
    alias: "PDS",
    name: "newSequence",
  },
  {
    alias: "CDM",
    name: "oldSequence",
  },
];

export const columns = ["Flight No.", "TOBT", "TSAT", "EXOT", "TTOT"];

export const runways = [
  { alias: "Runway 1", id: 1 },
  { alias: "Runway 2", id: 2 },
];

export const errorNameLabel = {
  airline: "Airline",
  iata: "IATA",
  icao: "ICAO",
};
