import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/APIRequest";
import { successMessage } from "./toastAlertSlice";

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  async (data) => {
    const response = await getRequest("/api/alerts", data);
    return response;
  }
);

export const restoreAlerts = createAsyncThunk(
  "alerts/restoreAlerts",
  async (dataIds, { dispatch }) => {
    const response = await putRequest("/api/alerts/restore/multi", {
      ids: dataIds,
    });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore records successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

export const deleteAlerts = createAsyncThunk(
  "alerts/deleteAlerts",
  async (dataIds, { dispatch }) => {
    const response = await deleteRequest("/api/alerts", { ids: dataIds });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Delete alert successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

export const createAlert = createAsyncThunk(
  "alerts/createAlert",
  async (data, { dispatch }) => {
    const response = await postRequest("/api/alerts", data);
    if (!response.error) {
      dispatch(
        successMessage({
          message: response.message || "Create alert successfully",
          back: true,
        })
      );
      return response;
    }
    throw new Error("Create alert failed");
  }
);

export const updateAlert = createAsyncThunk(
  "alerts/updateAlert",
  async ({ alert_id, data }, { dispatch }) => {
    const response = await putRequest(`/api/alerts/${alert_id}`, data);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Update alert successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

export const fetchAlert = createAsyncThunk(
  "alerts/fetchAlert",
  async (dataId) => {
    const response = await getRequest(`/api/alerts/${dataId}`);
    return response;
  }
);

export const checkAlertQuery = createAsyncThunk(
  "alerts/checkAlertQuery",
  async (data) => {
    const response = await postRequest("/api/alerts/checkquery", data);
    if (!response.error) {
      return response.query;
    }
  }
);

const initialState = {
  alerts: [],
  alert: {},
  loading: false,
  total: 0,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    reinitAlerts: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.alerts = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAlerts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreAlerts.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreAlerts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAlerts.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAlerts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAlert.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.alerts = state.alerts.map((alert) =>
          alert.id === action.payload.id ? action.payload : alert
        );
        state.alert = action.payload;
      })
      .addCase(updateAlert.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.alert = action.payload;
      })
      .addCase(fetchAlert.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkAlertQuery.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAlertQuery.fulfilled, (state, action) => {
        state.loading = false;
        state.query = action.payload;
      })
      .addCase(checkAlertQuery.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitAlerts } = alertsSlice.actions;

export default alertsSlice.reducer;
