import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, mergePatchRequest, postRequest } from "utils/APIRequest";
import { getKeycloakAccount, updateKeycloakAccount } from "./keycloakActions";
import { errorMessage } from "./toastAlertSlice";

const initialState = {
  selectedFlights: { ids: [], numbers: [], registrations: [] },
  airlines: [],
  roles: [],
  info: {},
  loading: false,
};

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async () => {
    const response = await getKeycloakAccount();
    if (response) {
      const data = {
        ...response.attributes,
        email: [response.email],
        name: [response.username],
      };
      return data;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (data) => {
    const response = await updateKeycloakAccount(data);
    return response;
  }
);

export const uploadUserImage = createAsyncThunk(
  "user/uploadUserImage",
  async ({ id, formData }) => {
    const response = await postRequest(
      `/api/users/upload-image/${id}`,
      formData,
      {
        Cache: "no-cache",
        Authorization: `Bearer ${localStorage.getItem(storageKeys.TOKEN)}`,
      },
      true
    );
    if (!response.error) {
      return response.path;
    }
  }
);

export const removeUserImage = createAsyncThunk(
  "user/removeUserImage",
  async ({ id, data }) => {
    const response = await mergePatchRequest(
      `/api/users/clear-image/${id}`,
      data
    );
    if (!response.error) {
      return "";
    } else {
      errorMessage({ message: response.message });
    }
  }
);

export const fetchUserRolesAndAirlines = createAsyncThunk(
  "user/fetchUserRolesAndAirlines",
  async () => {
    const response = await getRequest("/api/users/roles");
    if (!response.error) {
      return {
        airlines: response.airlines,
        roles: response.roles,
      };
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reinitUser: () => {
      localStorage.removeItem(storageKeys.SELECTED_FLIGHTS);
      return initialState;
    },
    setSelectedFlights: (state, action) => {
      state.selectedFlights = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadUserImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadUserImage.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(uploadUserImage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeUserImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeUserImage.fulfilled, (state, action) => {
        state.loading = false;

        state.info = action.payload;
      })
      .addCase(removeUserImage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUserRolesAndAirlines.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserRolesAndAirlines.fulfilled, (state, action) => {
        state.loading = false;
        state.airlines = action.payload.airlines;
        state.roles = action.payload.roles;
      })
      .addCase(fetchUserRolesAndAirlines.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitUser, setSelectedFlights } = userSlice.actions;
export default userSlice.reducer;
