import React, { useState, useEffect } from "react";
import _ from "lodash";
import Checkbox from "../CheckBox";
import "./style.css";

const ColumnsFilter = (props) => {
  const [activeColumns, setActiveColumns] = useState({});

  useEffect(() => {
    setActiveColumns(props.activeColumns);

    return () => {};
  }, []);

  const toggleColumn = ({ keyCode, target: { id } }) => {
    if (keyCode && keyCode !== 13) {
      return null;
    }

    setActiveColumns((prev) => {
      const currentActiveList = { ...prev, [id]: !prev[id] };
      props.updateColumns && props.updateColumns(currentActiveList);
      return currentActiveList;
    });
  };

  const handleClick = ({ keyCode }) => {
    if (keyCode && keyCode !== 13) {
      return null;
    }
    props.closeFilter("showColmnsFilter");
  };
  const { data = [] } = props;

  return (
    <div className="dashboard-filter filter-by">
      <div className="header-section">
        <div className="dashboard-filter filter-header">Show/Hide columns</div>
        <div
          tabIndex="0"
          role="button"
          data-confirm="no"
          onKeyDown={handleClick}
          onClick={handleClick}
          className="dashboard-filter filter-close"
        >
          &times;
        </div>
      </div>
      <div className="dashboard-filter filter-items-wrapper">
        {data.map((element) => (
          <div className="filter-item ml-sm" key={element.key}>
            <Checkbox
              key={element.key}
              onChange={toggleColumn}
              id={element.label || element.title}
              checked={!!activeColumns[element.label || element.title]}
              name={element.key}
              label={element.label || element.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnsFilter;
