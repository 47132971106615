import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  deleteRequest,
  putRequest,
  postRequest,
} from "utils/APIRequest";
import { errorMessage, successMessage } from "./toastAlertSlice";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (data) => {
  const response = await getRequest("/api/users", data);
  return response;
});

export const deleteUsers = createAsyncThunk(
  "users/deleteUsers",
  async (userIds, { dispatch }) => {
    const url = `/api/users`;
    const response = await deleteRequest(url, { ids: userIds });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "User deleted successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreUsers = createAsyncThunk(
  "users/restoreUsers",
  async (userIds) => {
    const url = `/api/users/restore/multi`;
    const response = await putRequest(url, { ids: userIds });
    return response;
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data, { dispatch }) => {
    const response = await postRequest(`/api/users?`, data);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "User created successfully",
          back: true,
        })
      );
      return response.data;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ user_id, dataToSave }, { dispatch }) => {
    const response = await putRequest(`/api/users/${user_id}?`, dataToSave);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "User updated successfully",
          back: true,
        })
      );
      return response;
    } else {
      dispatch(
        errorMessage({
          message: "User updated failed",
          back: true,
        })
      );
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "users/fetchUserDetails",
  async (userId) => {
    const response = await getRequest(`/api/users/${userId}`);
    return response;
  }
);

const initialState = {
  users: [],
  user: {},
  loading: false,
  total: 0,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reinitUsers: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreUsers.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = [action.payload, ...state.users];
      })
      .addCase(createUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload;

        state.user = {
          ...data,
          organization: data.organizations[0],
          airlines: data.airlines.map((e) => e.iata) || [],
          roles: data.roles.map((e) => e.role),
        };
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitUsers } = usersSlice.actions;

export default usersSlice.reducer;
