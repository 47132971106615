import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "utils/APIRequest";

export const fetchBHSBelts = createAsyncThunk(
  "bhs/fetchBHSBelts",
  async (data = {}) => {
    const response = await getRequest("/api/bhs/belts", data);
    const belts = response.map((el) => {
      const o = Object.assign({}, el);
      o.flights = [];
      return o;
    });
    return belts;
  }
);

export const fetchBHS = createAsyncThunk("bhs/fetchBHS", async (data = {}) => {
  const response = await getRequest("/api/bhs/index", data);
  return response;
});

const initialState = {
  belts: [],
  loadingBelts: false,
  total: 0,
  loadingBHS: false,
};

const bhsSlice = createSlice({
  name: "bhs",
  initialState,
  reducers: {
    reinitBHS: () => {
      return initialState;
    },
    newBeltSocket: (state, action) => {
      const { message, action: socketAction } = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBHSBelts.pending, (state) => {
        state.loadingBelts = true;
      })
      .addCase(fetchBHSBelts.fulfilled, (state, action) => {
        state.loadingBelts = false;
        state.belts = action.payload;
      })
      .addCase(fetchBHSBelts.rejected, (state) => {
        state.loadingBelts = false;
      })
      .addCase(fetchBHS.pending, (state) => {
        state.loadingBHS = true;
      })
      .addCase(fetchBHS.fulfilled, (state, action) => {
        state.loadingBHS = false;
      })
      .addCase(fetchBHS.rejected, (state) => {
        state.loadingBHS = false;
      });
  },
});

export const { reinitBHS, newBeltSocket } = bhsSlice.actions;

export default bhsSlice.reducer;
