import queryString from "query-string";
import { storageKeys } from "utils/helpers";

const _requestOptions = (
  url,
  method = "GET",
  contentType = "application/json"
) => {
  const parsedUrl = new URL(url);
  let realHeaders = {};
  realHeaders["Host"] = parsedUrl.host;
  realHeaders["Authorization"] = `Bearer ${localStorage.getItem(
    storageKeys.TOKEN
  )}`;
  realHeaders["Content-Type"] = contentType;

  return {
    host: parsedUrl.hostname,
    port: parsedUrl.port,
    path: parsedUrl.pathname,
    method,
    headers: realHeaders,
  };
};

export const updateKeycloakAccount = async (data) => {
  const url = process.env.REACT_APP_KEYCLOAK_ACCOUNT_URL;
  const options = _requestOptions(url, "POST");
  const payload = Object.assign(
    {
      body: JSON.stringify(data),
    },
    options
  );

  const response = await keycloakFetch(url, payload);

  return response.status === 204;
};

export const getKeycloakAccount = async () => {
  const url = process.env.REACT_APP_KEYCLOAK_ACCOUNT_URL;
  const options = _requestOptions(url, "GET");
  const payload = Object.assign(options);
  const response = await keycloakFetch(url, payload);
  const account = await response.json();

  return account;
};

export const logoutKeycloak = async () => {
  const url = `${process.env.REACT_APP_KEYCLOAK_URL}logout`;

  const post_data = queryString.stringify({
    refresh_token: localStorage.getItem(storageKeys.REFRESH_TOKEN),
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  });

  const options = _requestOptions(
    url,
    "POST",
    "application/x-www-form-urlencoded"
  );
  const payload = Object.assign(
    {
      body: post_data,
    },
    options
  );

  return await keycloakFetch(url, payload);
};

export const keyCloakToken = async (params) => {
  const url = `${process.env.REACT_APP_KEYCLOAK_URL}token`;

  const post_data = queryString.stringify(params);

  const options = _requestOptions(
    url,
    "POST",
    "application/x-www-form-urlencoded"
  );

  const payload = Object.assign(
    {
      body: post_data,
    },
    options
  );

  let response = await keycloakFetch(url, payload);

  if (response.status === 200) {
    return await response.json();
  } else throw new Error("get token failed");
};

export const logOut = () => async () => {
  try {
    const response = await logoutKeycloak();
    if (response) {
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token");
      localStorage.removeItem("TOKEN_EXPIRES_IN");
      localStorage.removeItem("REFRESH_EXPIRES_IN");

      window.location.href = "/login";

      return response;
    }
  } catch (error) {
    return false;
  }
};

export const checkIfTokensValid = () => {
  const tokenExpires = localStorage.getItem("TOKEN_EXPIRES_IN");
  const expiredAt = new Date(tokenExpires).getTime();
  const currentDate = new Date().getTime();
  let expire = expiredAt - currentDate;

  return expire >= 1;
};

export const getRefreshToken = async () => {
  const params = {
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    grant_type: "refresh_token",
    redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    state: "KeyCloak",
    refresh_token: localStorage.getItem(storageKeys.REFRESH_TOKEN),
  };

  return await keyCloakToken(params);
};

export const getKeyCloakToken = async () => {
  if (!checkIfTokensValid() && localStorage.getItem(storageKeys.TOKEN)) {
    const response = await getRefreshToken();
    updateAuthDetails(response);

    return response.access_token;
  }

  return localStorage.getItem(storageKeys.TOKEN);
};
export const getAccessToken = async (code, codeVerifier) => {
  let params = {
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    code: code,
    grant_type: "authorization_code",
    redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    code_verifier: codeVerifier,
    state: "KeyCloak",
  };

  return await keyCloakToken(params);
};

export const updateAuthDetails = (object = {}) => {
  const { access_token, expires_in, refresh_token, refresh_expires_in } =
    object;

  localStorage.setItem("token", access_token);
  localStorage.setItem(
    "TOKEN_EXPIRES_IN",
    new Date(new Date().getTime() + expires_in * 1000)
  );
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem(
    "REFRESH_EXPIRES_IN",
    new Date(new Date().getTime() + refresh_expires_in * 1000)
  );
};
