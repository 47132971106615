import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "utils/APIRequest";

export const fetchActiveAlerts = createAsyncThunk(
  "activeAlerts/fetchActiveAlerts",
  async (data) => {
    const url = `/api/activealerts/index`;
    const response = await getRequest(url, data);
    if (!response.error) {
      return response;
    }
  }
);

export const fetchGeneralAlerts = createAsyncThunk(
  "activeAlerts/fetchGeneralAlerts",
  async (data) => {
    const response = await getRequest("/api/generalalerts", data);
    if (!response.error) {
      return response;
    }
  }
);

const initialState = {
  alerts: {},
  notifications: {},
  "general alerts": {},
  loading: false,
  total: 0,
};

const activeAlertsSlice = createSlice({
  name: "activeAlerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActiveAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload.alerts || {};
        state.notifications = action.payload.notifications || {};
        state.loading = false;
      })
      .addCase(fetchActiveAlerts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGeneralAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGeneralAlerts.fulfilled, (state, action) => {
        state["general alerts"] = action.payload || {};
        state.loading = false;
      })
      .addCase(fetchGeneralAlerts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default activeAlertsSlice.reducer;
