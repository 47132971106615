import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, putRequest } from "utils/APIRequest";

export const fetchLayouts = createAsyncThunk(
  "layout/fetchLayouts",
  async (data = {}) => {
    const response = await getRequest("/api/layouts", data);
    if (!response.error) {
      return response;
    }
  }
);

export const updateUserLayoutConfig = createAsyncThunk(
  "layout/updateUserLayoutConfig",
  async ({ id, newLayout = {} }) => {
    const response = await putRequest(`/api/layouts/user/${id}`, newLayout);
    if (!response.error) {
      // dispatch({
      // 	type: "layout/UPDATE_USER_LAYOUT",
      // 	payload: { ...response.layout_config },
      // });
      return response;
    }
  }
);

export const saveUserLayout = createAsyncThunk(
  "layout/saveUserLayout",
  async (payload, thunkAPI) => {
    const { data, cb } = payload;
    try {
      const response = await postRequest(`/api/layouts`, data);
      if (!response.error) {
        thunkAPI.dispatch({
          type: "layout/UPDATE_USER_LAYOUT",
          payload: response.data.layout_config,
        });
        thunkAPI.dispatch({
          type: "layout/CHANGE_LAYOUT",
          payload: {
            alias: response.data.alias,
            id: response.data.id,
            direction: response.data.direction,
          },
        });
        cb && cb();
        return response.data;
      } else {
        throw new Error("Failed to save user layout");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateFavoriteLayout = createAsyncThunk(
  "layout/updateFavoriteLayout",
  async (payload, thunkAPI) => {
    const { data, action, cb } = payload;
    try {
      const response = await putRequest(`/api/layouts`, data);
      if (!response.error) {
        thunkAPI.dispatch(
          showSuccessNotification(`${action} layout successfully`)
        );
        thunkAPI.dispatch(updateFavoriteLayoutSuccess(response.data));
        cb && cb();
        return response.data;
      } else {
        throw new Error(`Failed to ${action.toLowerCase()} layout`);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const clearLayouts = (layouts) => {
  return layouts.map((ele) => {
    if (ele.user_role_id) {
      return ele;
    }
    for (const key in ele.layout_config) {
      ele.layout_config[key].content = "";
    }

    return ele;
  });
};

const initialState = {
  layouts: [],
  layout: { alias: "Single layout", id: 1, direction: "row" },
  loading: false,
  total: 0,
  activeFields: {},
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    reinitLayouts: () => initialState,
    reinitSelectedLayout: (state) => {
      state.layout = initialState.layout;
    },
    changeLayout: (state, action) => {
      state.layouts = clearLayouts(state.layouts);
      state.layout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLayouts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLayouts.fulfilled, (state, action) => {
        state.loading = false;
        state.layouts = action.payload;
      })
      .addCase(fetchLayouts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUserLayoutConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserLayoutConfig.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserLayoutConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveUserLayout.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveUserLayout.fulfilled, (state, action) => {
        state.loading = false;
        state.layouts = [...state.layouts, action.payload];
      })
      .addCase(saveUserLayout.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateFavoriteLayout.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFavoriteLayout.fulfilled, (state, action) => {
        state.loading = false;
        state.layouts = [
          ...state.layouts.filter((ele) => !ele.user_role_id),
          ...action.payload.filter((ele) => !ele.deleted),
        ];
      })
      .addCase(updateFavoriteLayout.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitLayouts, reinitSelectedLayout, changeLayout } =
  layoutSlice.actions;

export default layoutSlice.reducer;
