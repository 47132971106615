import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reinitNotification: (state) => initialState,
    showSuccessNotification: (state, action) => {
      state.type = "success";
      state.message = action.payload;
    },
    showErrorNotification: (state, action) => {
      state.type = "error";
      state.message = action.payload;
    },
  },
});

export const {
  reinitNotification,
  showSuccessNotification,
  showErrorNotification,
} = notificationSlice.actions;
export default notificationSlice.reducer;
