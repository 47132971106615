import { configureStore } from "@reduxjs/toolkit";
import { middleware as tooltip } from "redux-tooltip";
import { reducer as tooltipReducer } from "redux-tooltip";
import authSlice from "features/authSlice";
import toastAlertSlice from "features/toastAlertSlice";
import systemParamsSlice from "features/systemParamsSlice";
import userSlice from "features/userSlice";
import activeAlertsSlice from "features/activeAlertsSlice";
import acdmSlice from "features/acdmSlice";
import usersSlice from "features/usersSlice";
import alertsSlice from "features/alertsSlice";
import organizationsSlice from "features/organizationsSlice";
import airlinesSlice from "features/airlinesSlice";
import rolesSlice from "features/rolesSlice";
import messagesSlice from "features/messagesSlice";
import layoutSlice from "features/layoutSlice";
import appSlice from "features/appSlice";
import notificationsSlice from "features/notificationsSlice";
import notificationLogsSlice from "features/notificationLogsSlice";
import auditSlice from "features/auditSlice";
import bhsSlice from "features/bhsSlice";
import notificationSlice from "features/notificationSlice";

const store = configureStore({
  reducer: {
    Roles: rolesSlice,
    auth: authSlice,
    ToastAlert: toastAlertSlice,
    SystemParams: systemParamsSlice,
    UserInfo: userSlice,
    ActiveAlerts: activeAlertsSlice,
    Messages: messagesSlice,
    Layouts: layoutSlice,
    ACDM: acdmSlice,
    Users: usersSlice,
    Alerts: alertsSlice,
    Organizations: organizationsSlice,
    Airlines: airlinesSlice,
    Notifications: notificationsSlice,
    Notification: notificationSlice,
    manualUpdateLogs: notificationLogsSlice,
    AuditTrails: auditSlice,
    general: appSlice,
    BHS: bhsSlice,
    tooltip: tooltipReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tooltip),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
