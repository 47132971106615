import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/APIRequest";
import { errorMessage, successMessage } from "./toastAlertSlice";

const initialState = {
  roles: [],
  role: {},
  permissions: [],
  loading: false,
  total: 0,
};

export const fetchRoles = createAsyncThunk("roles/fetchRoles", async (data) => {
  const response = await getRequest(`/api/roles`, data);
  return response;
});

export const fetchRole = createAsyncThunk("roles/fetchRole", async (id) => {
  const response = await getRequest(`/api/roles/${id}`);
  if (!response.error) {
    return response;
  }
});

export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async ({ role_id, dataToSave }, { dispatch }) => {
    const response = await putRequest(`/api/roles/${role_id}`, dataToSave);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Role updated successfully",
          back: true,
        })
      );
      return response;
    } else {
      dispatch(
        errorMessage({
          message: "Role updated failed",
          back: true,
        })
      );
    }
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (data, { dispatch }) => {
    const response = await postRequest(`/api/roles`, data);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Role created successfully",
          back: true,
        })
      );
      return response.data;
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  "roles/fetchPermissions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getRequest(`/api/permissions/index`, data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRoles = createAsyncThunk(
  "roles/deleteRoles",
  async (data, { dispatch }) => {
    const response = await deleteRequest(`/api/roles`, { ids: data });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Delete records successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreRoles = createAsyncThunk(
  "roles/restoreRoles",
  async (dataIds, { dispatch }) => {
    const response = await putRequest(`/api/roles/restore/multi`, {
      ids: dataIds,
    });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore records successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

const binaryParser = (bin) => {
  return {
    view: +bin[0] || 0,
    create: +bin[1] || 0,
    update: +bin[2] || 0,
    delete: +bin[3] || 0,
  };
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    reinitRoles(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.loading = false;
        state.role = action.payload;
      })
      .addCase(fetchRole.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload.data.map((e) =>
          Object.assign(
            { ...e },
            { permission_bin: binaryParser(e.permission_bin) }
          )
        );
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createRole.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoles.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRoles.rejected, (state) => {
        state.loading = false;
      })

      .addCase(restoreRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreRoles.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreRoles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
