import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "utils/APIRequest";

export const fetchSystemParams = createAsyncThunk(
  "systemParams/fetchSystemParams",
  async () => {
    const response = await getRequest("/api/sysparams");
    return response;
  }
);

export const getTimeZone = createAsyncThunk(
  "systemParams/getTimeZone",
  async () => {
    const response = await getRequest("/api/time-zone");
    return response;
  }
);

const systemParamsSlice = createSlice({
  name: "systemParams",
  initialState: {
    systemParams: {},
    timeZone: "",
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemParams.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSystemParams.fulfilled, (state, action) => {
        state.loading = false;
        state.systemParams = action.payload;
      })
      .addCase(fetchSystemParams.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTimeZone.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTimeZone.fulfilled, (state, action) => {
        state.loading = false;
        state.timeZone = action.payload?.timeZone;
      })
      .addCase(getTimeZone.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default systemParamsSlice.reducer;
