import React, { memo, useEffect } from "react";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { isAuthorized } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchUnReadMessages } from "features/messagesSlice";
import { readObject, storageKeys } from "utils/helpers";
import { setSelectedFlights } from "features/userSlice";

const getMessagesCount = (unreadMessage = {}) => {
  let count = 0;
  Object.keys(unreadMessage).map(
    (ele) => (count += unreadMessage[ele].chats?.length),
  );

  return count;
};

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: 10,
    height: 16,
    width: 16,
    color: "white",
    backgroundColor: "#205CF6",
  },
  iconButton: {
    paddingLeft: 6,
    height: 35,
    width: 35,
    "&:hover": {
      backgroundColor: "#2A3D52",
    },
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#1E2D3F",
    padding: "5px 8px",
  },
}));

const tooltip = {
  "& .MuiTooltip-tooltip": {
    color: "white",
    fontSize: "12px !important",
    borderRadius: 1,
    marginTop: 0.7,
  },
};

const MessagesNumber = memo(() => {
  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    SELECTED_FLIGHTS: SELECTED_FLIGHTS_IDS,
    SELECTED_FLIGHTS_NUMBERS,
    SELECTED_FLIGHTS_REGISTRATIONS,
  } = storageKeys;

  const handleSelectedFlights = () => {
    const selectedFlights = readObject(SELECTED_FLIGHTS_IDS);
    const numbers = readObject(SELECTED_FLIGHTS_NUMBERS);
    const registrations = readObject(SELECTED_FLIGHTS_REGISTRATIONS);
    if (selectedFlights) {
      setSelectedFlights({
				ids: selectedFlights,
				numbers,
				registrations,
			})
    }
  };

  useEffect(() => {
    handleSelectedFlights();
    dispatch(fetchUnReadMessages());
  }, []);

  const numberOfMessages = getMessagesCount(
    useSelector((state) => state.Messages.unreadMessage),
  );

  const { roles } = useSelector((state) => state.auth?.user);
  const { canView } = isAuthorized(roles, "channels");
  return canView ? (
    <div>
      <StyledTooltip
        title="Messages"
        components={{ Tooltip: Stack }}
        sx={tooltip}
      >
        <IconButton className={classes.iconButton}>
          <Badge
            badgeContent={numberOfMessages}
            max={20}
            style={{ width: 22 }}
            classes={{ badge: classes.badge }}
          >
            <EmailIcon
              style={{ color: "white" }}
              onClick={() => {
                navigate("/admin/messages");
              }}
            />
          </Badge>
        </IconButton>
      </StyledTooltip>
    </div>
  ) : (
    <></>
  );
});

export default MessagesNumber;
