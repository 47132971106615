import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  putRequest,
  postRequest,
} from "utils/APIRequest";
import { successMessage } from "./toastAlertSlice";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (data) => {
    const response = await getRequest("/api/notifications", data);
    return response;
  }
);

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotifications",
  async (data, { dispatch }) => {
    const response = await deleteRequest("/api/notifications", { ids: data });

    if (!response.error) {
      dispatch(
        successMessage({
          message: "Delete notification successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

export const restoreNotifications = createAsyncThunk(
  "notifications/restoreNotifications",
  async (data, { dispatch }) => {
    const response = await putRequest("/api/notifications/restore/multi", {
      ids: data,
    });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore notification successfully",
          back: true,
        })
      );
      return response;
    }
  }
);

export const createNotifications = createAsyncThunk(
  "notifications/createNotifications",
  async (data, { dispatch }) => {
    const response = await postRequest("/api/notifications", data);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Create notification successfully",
          back: true,
        })
      );
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/updateNotifications",
  async ({ notification_id, data }, { dispatch }) => {
    const response = await putRequest(
      `/api/notifications/${notification_id}`,
      data
    );
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Update notification successfully",
          back: data.back,
        })
      );
    }
  }
);

export const fetchNotification = createAsyncThunk(
  "notifications/fetchNotification",
  async (dataId) => {
    const response = await getRequest(`/api/notifications/${dataId}`);
    return response;
  }
);

const initialState = {
  notifications: [],
  notification: {},
  loading: false,
  total: 0,
};
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reinitNotifications: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreNotifications.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotifications.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotifications.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notification = action.payload;
      })
      .addCase(fetchNotification.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
