import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/APIRequest";
import { successMessage } from "./toastAlertSlice";

export const fetchAirlines = createAsyncThunk(
  "airlines/fetchAirlines",
  async (data) => {
    const response = await getRequest(`/api/airlines`, data);
    return response;
  }
);

export const uploadAirlineImage = createAsyncThunk(
  "airlines/uploadAirlineImage",
  async ({ airlineId, data }) => {
    const url = `/api/airlines/uploadimage/${airlineId}`;
    const response = await postRequest(url, data);
    return response;
  }
);

export const deleteAirline = createAsyncThunk(
  "airlines/deleteAirline",
  async (ids, { dispatch }) => {
    const url = `/api/airlines`;
    const response = await deleteRequest(url, { ids });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Delete airline successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreAirline = createAsyncThunk(
  "airlines/restoreAirline",
  async (ids, { dispatch }) => {
    const url = `/api/airlines/restore/multi`;
    const response = await putRequest(url, { ids });
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Restore airline successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const restoreOrganizationAirline = createAsyncThunk(
  "airlines/restoreOrganizationAirline",
  async ({ ids, cb }) => {
    const url = "/api/airlines/restore/organizationAirline";
    const response = await putRequest(url, { ids });
    if (!response.error) {
      cb && cb();
    }
    return response;
  }
);

export const restoreUsersAirline = createAsyncThunk(
  "airlines/restoreUsersAirline",
  async ({ ids, cb }) => {
    const url = "/api/airlines/restore/usersAirline";
    const response = await putRequest(url, { ids });
    if (!response.error) {
      cb && cb();
    }
    return response;
  }
);

export const createAirline = createAsyncThunk(
  "airlines/createAirline",
  async (data, { dispatch }) => {
    const url = `/api/airlines`;
    const response = await postRequest(url, data, {}, true);
    if (!response.error) {
      dispatch(
        successMessage({
          message: "User created successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const updateAirline = createAsyncThunk(
  "airlines/updateAirline",
  async ({ airline_id, data }, { dispatch }) => {
    const url = `/api/airlines/${airline_id}`;
    const response = await putRequest(
      url,
      data,
      { Authorization: `Bearer ${localStorage.getItem("token")}` },
      true
    );
    if (!response.error) {
      dispatch(
        successMessage({
          message: "Update airline successfully",
          back: true,
        })
      );
    }
    return response;
  }
);

export const fetchAirline = createAsyncThunk(
  "airlines/fetchAirline",
  async (dataId) => {
    const url = `/api/airlines/${dataId}`;
    const response = await getRequest(url);
    return response;
  }
);

const initialState = {
  airlines: [],
  airline: {},
  total: 0,
  loading: false,
};

const airlinesSlice = createSlice({
  name: "airlines",
  initialState,
  reducers: {
    reinitAirlines: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirlines.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAirlines.fulfilled, (state, action) => {
        state.airlines = action.payload.data;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(fetchAirlines.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadAirlineImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadAirlineImage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadAirlineImage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAirline.rejected, (state) => {
        state.loading = false;
      })

      .addCase(restoreAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreAirline.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreOrganizationAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreOrganizationAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreOrganizationAirline.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreUsersAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreUsersAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(restoreUsersAirline.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAirline.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAirline.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAirline.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAirline.fulfilled, (state, action) => {
        state.airline = action.payload;
        state.loading = false;
      })
      .addCase(fetchAirline.rejected, (state) => {
        state.loading = false;
      })
      .addDefaultCase((state) => {});
  },
});

export const { reinitAirlines } = airlinesSlice.actions;

export default airlinesSlice.reducer;
