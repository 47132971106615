import React, { useEffect, useState, useCallback } from "react";
import GridHeader from "./GridHeader";
import dataFilter from "../../../common/filter_manager/data_filter";
import dataSort from "../../../common/sort_manager/data_sort";
import ColorsCode from "../../../common/widgets_color_codes";
import Row from "./Row";
import { sortTypes } from "utils/helpers";
import { useSelector } from "react-redux";
import UpdateField from "../update_popup";
import { acdmCodes } from "components/common/constant";

const search = (data, searchBy) => {
  const value = searchBy.toLowerCase() || "";
  const result = data.filter((obj) =>
    _.values(obj).some((field) => {
      if (typeof field === "string" && field.toLowerCase().includes(value)) {
        return obj;
      }
    })
  );
  return result;
};

const ACDMTableWrapper = (props) => {
  const [data, setData] = useState([]);
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const { alerts } = useSelector((state) => state.ActiveAlerts);
  const { acdmFlights, sortBy, sortData, filterBy, headers, searchBy } = props;

  const { timeZone } = useSelector((state) => state.SystemParams);

  useEffect(() => {
    let filteredData = Object.keys(filterBy || {}).length
      ? dataFilter(acdmFlights, filterBy, timeZone)
      : acdmFlights;

    filteredData = !searchBy
      ? filteredData
      : search([...filteredData], searchBy);

    filteredData?.map((flight) => {
      const updatedFlight = {
        ...flight,
        alerts: Object.values(alerts).filter(
          ({ flight_number }) =>
            flight_number === flight.arrival_flight_number ||
            flight_number === flight.departure_flight_number
        ).length,
      };

      headers.map((field) => {
        const more_accurate_field = renderAcdmFieldAccuracy(
          field,
          updatedFlight
        );
        updatedFlight[field.name] = updatedFlight[more_accurate_field.name];
        updatedFlight[`${field.name}_color`] = more_accurate_field.color;
      });
    });

    const sortedData = dataSort(filteredData, sortBy);
    setData(sortedData);
  }, [filterBy, sortBy, acdmFlights, alerts, searchBy]);

  useEffect(() => {
    const overflow = props.showModal ? "hidden" : "auto";
    const myElement = document.querySelector("#acdm-table-overflow");

    if (myElement) {
      Object.assign(myElement?.style, {
        overflow,
      });
    }
  });

  useEffect(() => {
    disableComponent();
  }, [props.showModal, data]);

  const disableComponent = () => {
    const pointerEvents = "none";
    const disableElement = document.querySelectorAll(
      ".tabs , .block-resize-icon, .acdm-icon, .container-config-header, .header-btns"
    );

    disableElement.forEach((element) => {
      if (props.showModal) {
        Object.assign(element?.style, {
          pointerEvents,
        });
      } else {
        element.style.removeProperty("pointer-events");
      }
    });
  };

  const renderAcdmFieldAccuracy = (field, flight) => {
    const accuracy = field.accuracy || [];
    let name, color;
    for (let i = 0; i < accuracy.length; i += 1) {
      name = accuracy[i].name;
      color = accuracy[i].color;

      if (flight[name]) break;
    }
    return { name, color };
  };

  const onClickFieldHandler = useCallback(
    (flightData) => {
      props.setUpdateData(flightData);
      props.setSelectedFlight(flightData.flight);
      props.setShowModal(true);
      props.toggleShowModal(false);
    },
    [props.showModal]
  );

  const renderAcdmGridHeaders = () => {
    return headers.map((field) => {
      const desc_sort = sortBy[field.name] || sortTypes.DESCEND;
      return (
        <GridHeader
          key={field.title}
          title={field.title}
          name={field.name}
          desc_sort={desc_sort}
          sortData={sortData}
          sortBy={sortBy}
        />
      );
    });
  };

  const clearData = () => {
    props.setShowModal(false);
    props.toggleShowModal(true);
    props.setSelectedFlight({});
    props.setUpdateData({});
  };

  const closeModal = () => {
    clearData();
    props.setSelectedFlight({});
  };

  const modalUpdateCallBack = () => {
    clearData();
  };

  const containerFontSize = props.fullscreenBlock
    ? "widget-table-size"
    : "widget-table";

  return (
    <div className="acdm-table-wrapper" id="acdm-table-overflow">
      {props.showModal && (
        <UpdateField
          data={props.updateData}
          handleClose={closeModal}
          modalUpdateCallBack={modalUpdateCallBack}
          x={x}
          y={y}
        />
      )}
      <table className={`${containerFontSize} acdm-table`}>
        <thead>
          <tr>
            <th className="acdm-fixed-header">-</th>
            {renderAcdmGridHeaders()}
          </tr>
        </thead>
        <tbody>
          {data?.map((flight) => {
            return (
              <Row
                selectedFlight={props.selectedFlight}
                key={flight.id}
                flight={{ ...flight }}
                headers={headers}
                onClickFieldHandler={onClickFieldHandler}
                clearData={clearData}
                setY={setY}
                setX={setX}
              />
            );
          })}
        </tbody>
      </table>
      <ColorsCode widgetColors={acdmCodes} />
    </div>
  );
};

export default ACDMTableWrapper;
