import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reinitNotification } from "features/notificationSlice";

const backgroundColor = {
  error: "#C32638",
  success: "#23C552",
};

const icons = {
  error: "xmark",
  success: "check",
};

const Notification = ({ message, type }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        dispatch({ type: reinitNotification });
      }, 5000);
    }
  }, [message]);

  const handleClose = () => {
    dispatch({ type: reinitNotification });
  };

  return (
    <>
      <div className={`notification-modal`}>
        <div
          style={{ zIndex: 1301, backgroundColor: backgroundColor[type] }}
          className="notification-content"
        >
          <div
            tabIndex="0"
            role="button"
            onKeyDown={handleClose}
            onClick={handleClose}
            className="close"
          >
            &times;
          </div>
          <div className="notification-text">
            <span className="close-icon">
              <i className={`fa-solid fa-circle-${icons[type]}`} />
            </span>
            <span> {`${message}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
