import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateFormat: null,
  alerts: null,
  generalAlerts: null,
  sidebar: false,
  activeFlight: {},
  airplanes: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload;
    },
    setAlerts: (state, action) => {
      state.alerts = action.payload;
    },
    setGeneralAlerts: (state, action) => {
      state.generalAlerts = action.payload;
    },
    toggleSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    activateFlight: (state, action) => {
      const flight = action.payload;
      state.activeFlight = flight;
    },
    deActivateFlight: (state) => {
      state.activeFlight = {};
    },
    updateAirplanes: (state, action) => {
      state.airplanes = action.payload;
    },
  },
});

export const {
  setDateFormat,
  setAlerts,
  setGeneralAlerts,
  toggleSidebar,
  activateFlight,
  deActivateFlight,
  updateAirplanes,
} = appSlice.actions;

export default appSlice.reducer;
