import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchLayouts, updateUserLayoutConfig } from "features/layoutSlice";
import LayoutList from "./LayoutList";
import styles from "./style.module.css";

const LayoutsGrid = (props) => {
  const [layoutList, setLayoutList] = useState({});
  const navigate = useNavigate();

  const { auth,Layouts } = useSelector((state) => state);
  const {layouts} = Layouts

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLayouts());
  }, []);

  useEffect(() => {
    getLayoutList();
  }, [props.widgets, layouts]);

  const getLayoutList = () => {
    let widgetList = [];

    if (!props.widgets.err) {
      widgetList = props.widgets.map((e) => e.permission_name);
    }
    const numberOfWidgets = widgetList.length;
    const layoutConfig = {};
    layouts
      .filter((e) => e.blocks_number <= numberOfWidgets)
      .forEach((e) => {
        layoutConfig[e.id] = {
          id: e.id,
          predefined: !!e.user_role_id,
          alias: e.alias,
          direction: e.direction,
          blocksNumber: e.blocks_number,
          blocks: e.layout_config,
        };
      });
    setLayoutList(layoutConfig);
  };

  const changeLayoutHandler = (layoutId) => {
    const currentLayout = layoutList[layoutId];

    if (currentLayout.predefined) {
      saveLayoutConfig(currentLayout);
    } else {
      changeLayout(currentLayout);
    }
  };

  const changeLayout = (newLayout) => {
    props.handleLayoutChange({
      id: newLayout.id,
      alias: newLayout.alias,
      direction: newLayout.direction,
    });
    navigate("/layout");
  };

  const saveLayoutConfig = async (layoutConfig) => {
    const blocksData = layoutConfig.blocks;

    const newLayout = {
      layout_config: {
        blocks: blocksData,
        direction: layoutConfig.direction,
      },
    };

    const dispatchResult = await dispatch(updateUserLayoutConfig({id: auth?.user?.roles[0].id, newLayout}));
    if (!dispatchResult.error){
      navigate("/");
      props.setDisplayLayoutGrid(false)
    }
  };

  return (
    <div className={classNames("layouts-grid", { show: props.display })}>
      <div className={styles.layout_grid_select_header}>SELECT A LAYOUT</div>
      <div className="layouts-grid-bar">
        <LayoutList
          layoutList={layoutList}
          activeLayout={props.activeLayout}
          selectHandler={changeLayoutHandler}
        />
      </div>
    </div>
  );
};

export default LayoutsGrid;
