import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RenderLayout from "./RenderLayout";
import {
  reinitSelectedLayout,
  saveUserLayout,
  showErrorNotification,
} from "redux/actions";
import { updateUserLayoutConfig } from "features/layoutSlice";
import { AuthContext } from "context/authWidget";

const LayoutsManager = () => {
  const dispatch = useDispatch();
  const { layouts, layout } = useSelector((state) => state.Layouts);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { widgetToView } = useContext(AuthContext);

  useEffect(() => {
    return () => {
      dispatch(reinitSelectedLayout());
    };
  }, []);

  const saveLayoutAsFavorite = (config, cb) => {
    const layoutConfig = {
      alias: config.alias,
      blocks_number: Object.keys(config.blocks).length,
      direction: config.direction,
      layout_config: config.blocks,
    };

    dispatch(
      saveUserLayout(layoutConfig, () => {
        cb();
      }),
    );
  };

  const parseSelectedLayoutConfig = (layoutConfig) => {
    const layout = structuredClone(layouts).find(
      (e) => +e.id === +layoutConfig.id,
    );

    for (const key in layout.layout_config) {
      layout.layout_config[key].content = layoutConfig.layoutConfig[key];
    }
    return layout.layout_config;
  };

  const checkSelectedLayout = (layoutConfig) => {
    const blocksData = parseSelectedLayoutConfig(layoutConfig);
    const undefinedBlocks = Object.values(blocksData).filter(
      (block) => !block.content
    );
    return undefinedBlocks.length > 0;
  };

  const saveLayoutConfig = async (layoutConfig) => {
    const blocksData = parseSelectedLayoutConfig(layoutConfig);
    const user = auth.user;
    const validateWidget = checkSelectedLayout(layoutConfig);

    if (validateWidget) {
      dispatch(showErrorNotification("Warning! Fill all widgets"));
      return;
    }

    const newLayout = {
      layout_config: {
        blocks: blocksData,
        direction: layoutConfig.direction,
      },
    };

    const dispatchResult = await dispatch(updateUserLayoutConfig({id: user.roles[0].id, newLayout}))
    if (!dispatchResult.error){
      navigate("/");
    }
  };

  if (widgetToView.length > 0) {
    return (
      <div className="sub-main-container">
        <RenderLayout
          widgets={widgetToView}
          layout={layout}
          saveLayoutConfig={saveLayoutConfig}
          saveLayoutAsFavorite={saveLayoutAsFavorite}
          checkSelectedLayout={checkSelectedLayout}
        />
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default LayoutsManager;
