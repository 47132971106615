import React, { memo, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "components/common/Tooltip";
import {
  reformatValue,
  formatFieldVal,
  activeManualFields,
  canUpdateFields,
  hasPermissions,
  isEditable,
} from "utils/helpers";

import { ACDMContext } from "../acdm_context";
import { showErrorNotification } from "redux/actions";
import { deActivateFlight } from "features/appSlice";
import handleEditableFieldsConditions from "../can_edit";
import TobtLeadTime from "../tobt_lead_time";

const TooltipContent = memo(({ formatFieldVal, field, flight, timeZone }) => {
  return field.accuracy.map((fieldAcc) => (
    <span key={`${fieldAcc.name}-${flight.aircraft_registration}`}>
      {fieldAcc.title}:
      {` ${formatFieldVal(flight[fieldAcc.name], field.type, timeZone, "HH:mm MMM, DD YYYY")}`}
      <br />
    </span>
  ));
});

const RenderField = ({
  field,
  flight,
  onClickFieldHandler,
  clearData,
  setX,
  setY,
}) => {
  const [toolTip, setToolTip] = useState(false);
  const { roles } = useSelector((state) => state.auth?.user);
  const { systemParams, timeZone } = useSelector((state) => state.SystemParams);
  const { tobt_update: tobtUpdate = {} } = systemParams;
  const ref = useRef();

  const contextApi = useContext(ACDMContext);
  const dispatch = useDispatch();
  const { updateField, width } = contextApi;
  const { isDisabled, type, needReformat } = field;
  const WIDGET_SIZE = 9;

  const getFieldsCanEdit = (field) => {
    const { editableFields } = field;
    const fieldsCanEdit = editableFields.filter((field) => {
      return (
        activeManualFields(field, systemParams) && hasPermissions(field, roles)
      );
    });

    return fieldsCanEdit;
  };

  const validateField = ({ name, flight, action }) => {
    const fieldsCanEdit = getFieldsCanEdit(field);
    if (fieldsCanEdit.length > 1) return true;

    const { canEdit, message } = handleEditableFieldsConditions(
      name,
      flight,
      roles,
      tobtUpdate,
      action,
    );

    if (canEdit) return canEdit;

    dispatch(showErrorNotification(message));
    clearData();
    return canEdit;
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const point = ref?.current?.getBoundingClientRect();
    setX(point?.x);
    setY(point?.y);

    const { editableFields } = field;
    if (!editableFields || !editableFields.length) {
      clearData();
      return;
    }
    const canEdit = canUpdateFields(
      editableFields,
      roles,
      updateField,
      field.title,
    );
    const editable = isEditable(canEdit, isDisabled);

    if (!editable) {
      clearData();
      return;
    }

    const fieldsCanEdit = getFieldsCanEdit(field);

    const data = {
      editableFields: fieldsCanEdit,
      field,
      flight,
    };

    if (field.editableFields.length > 0) {
      const isValidField = validateField({
        name: field.editableFields[0],
        flight,
      });

      if (isValidField) {
        const { aircraft_registration, flight_number } = flight;
        dispatch(deActivateFlight({ aircraft_registration, flight_number }));
        onClickFieldHandler(data);
      }
    }
  };

  const { name } = field;
  const value = reformatValue(name, flight[name], needReformat);

  const color = flight[`${name}_color`];
  const fieldColor = color && value ? color : "";
  const hasEditableField = field?.editableFields?.length > 0;

  const showToolTip = () => {
    setToolTip(true);
  };

  const hideTooltip = () => {
    setToolTip(false);
  };

  const widthSize = width < WIDGET_SIZE ? "table" : "";
  const fontIcon = {
    fontSize: width < WIDGET_SIZE ? "12px" : "14px",
  };

  return (
    field.title && (
      <>
        <td
          ref={ref}
          className={`${hasEditableField ? "editable" : ""} ${name}-${
            flight.aircraft_registration
          }`}
        >
          <span
            onClick={onClickHandler}
            onMouseEnter={showToolTip}
            onMouseLeave={hideTooltip}
            className={`acdm-field editable-field-${fieldColor} ${widthSize}`}
          >
            {!toolTip ? (
              formatFieldVal(value, type, timeZone)
            ) : (
              <Tooltip
                data={formatFieldVal(value, type, timeZone)}
                Compo={
                  <TooltipContent
                    formatFieldVal={formatFieldVal}
                    field={field}
                    flight={flight}
                    timeZone={timeZone}
                  />
                }
              />
            )}
            {updateField[field.title] && (
              <i className="fa-solid fa-pencil icon-pen" style={fontIcon} />
            )}
            {field.title == "OBT" && <TobtLeadTime flight={flight}/> }
          </span>
        </td>
      </>
    )
  );
};

export default RenderField;
