import React, { useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notfound from "components/common/ErrorsPages/NotfoundPage";
import SystemParamSystem from "components/SystemParamsSocket";
import { getUserFromToken } from "features/authSlice";
import { getTimeZone, fetchSystemParams } from "features/systemParamsSlice";
import routes from "./routes";
import { reinitToastAlert } from "features/toastAlertSlice";
import PrivateRouter from "./PrivateRoute";
import CheckSelectedRoles from "./CheckSelectedRoles";
import AuthorizationRoute from "./AuthorizationRoute";
import InfoModal from "components/common/modal_manager/info";
import "../components/admin_dashboard/main.css";

const AppRoutes = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { message, type, back } = useSelector((state) => state.ToastAlert);

  useEffect(() => {
    dispatch(getUserFromToken());
    dispatch(fetchSystemParams());
    dispatch(getTimeZone());
  }, [token]);

  const dispatch = useDispatch();

  const handleClose = () => {
    back && window.history.back();
    dispatch(reinitToastAlert());
  };

  return (
    <div className="main-container">
      <Suspense fallback="Loading...">
        {message && (
          <InfoModal
            message={message}
            cb={handleClose}
            icon="info"
            color={type === "error" ? "red" : "green"}
          />
        )}
        <Routes>
          {routes.map(
            ({ component: Component, path, name, permission, ...rest }) => (
              <Route
                key={path}
                path={path}
                {...rest}
                element={
                  <PrivateRouter>
                    {user?.id && (
                      <CheckSelectedRoles path={path}>
                        <SystemParamSystem />
                        {name ? (
                          <AuthorizationRoute
                            name={name}
                            permission={permission}
                          >
                            <Component />
                          </AuthorizationRoute>
                        ) : (
                          <Component />
                        )}
                      </CheckSelectedRoles>
                    )}
                  </PrivateRouter>
                }
              />
            )
          )}
          {user?.id && (
            <Route
              path="*"
              element={
                <PrivateRouter>
                  <Notfound />
                </PrivateRouter>
              }
            />
          )}
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRoutes;
