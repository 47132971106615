import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTimeZone } from "features/systemParamsSlice";
import { FETCH_SYSTEM_PARAMS_SUCCESS } from "redux/constants";
import { SocketAction, SocketEvent, SocketListener } from "utils/helpers";
import setupSocket from "utils/socket_setup";

const SystemParamSocket = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const newSocket = setupSocket(process.env.REACT_APP_GENERAL_SOCKET_PATH);

    newSocket.emit(SocketEvent.ROOM, "system_params");

    newSocket.on(SocketListener.GENERAL, (data) => {
      const { action, message } = data;
      if (action === SocketAction.UPDATE_SYSTEM_PARAMS) {
        dispatch({ type: FETCH_SYSTEM_PARAMS_SUCCESS, data: message });
        dispatch( getTimeZone());
      }
    });
  }, []);

  return <>{children}</>;
};

export default SystemParamSocket;
