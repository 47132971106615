import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AsmgcsActions from "../redux/actions";
import { actions as reduxToolTipActoins } from "react-redux-tooltip";
import sizeMe from "react-sizeme";
import ASMGCS from "../components/widgets/asmgcs";
import { deActivateFlight } from "features/appSlice";

const config = { monitorHeight: true };

const AppSized = sizeMe(config)(ASMGCS);

const mapStateToProps = (state) => ({
  auth: state.auth,
  airplanes: state.general.airplanes,
  selectedAirplane: state.general.activeFlight,
  timeZone: state.SystemParams.timeZone,
  selectedFlights: state.UserInfo.selectedFlights,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showToolTips: reduxToolTipActoins.show,
      hideToolTips: reduxToolTipActoins.hide,
      updateAirplanes: AsmgcsActions.updateAirplanes,
      activateFlight: AsmgcsActions.activateFlight,
      deActivateFlight: deActivateFlight,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppSized);
