import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, patchRequest } from "utils/APIRequest";

export const fetchAcdm = createAsyncThunk(
  "acdm/fetchAcdm",
  async (data = {}) => {
    const response = await getRequest("/api/acdm/index", data);
    return response;
  }
);

export const outBoundMessages = createAsyncThunk(
  "acdm/outBoundMessages",
  async (data = {}, { dispatch }) => {
    const response = await postRequest(`/api/acdm/outboundmessages?`, data);
    if (!response.error) {
      return response;
    }
  }
);

export const AcdmActiveFields = createAsyncThunk(
  "acdm/fetchActiveFields",
  async () => {
    const response = await getRequest("/api/users/acdmfields");
    if (!response.error) {
      return response;
    }
  }
);

export const updateAcdmActiveFields = createAsyncThunk(
  "acdm/updateActiveFields",
  async (data, { dispatch }) => {
    const response = await patchRequest("/api/users/acdmfields", data);
    if (!response.error) {
      dispatch(AcdmActiveFields());
    }
  }
);

const initialState = {
  acdmFlights: [],
  systemParams: {},
  loading: false,
  total: 0,
  activeFields: {},
};

const acdmSlice = createSlice({
  name: "acdm",
  initialState,
  reducers: {
    reinitAcdm: () => {
      return initialState;
    },
    updateAcdmSocket: (state, action) => {
      state.acdmFlights = { ...state.acdmFlights, ...action.payload };
      state.loading = false;
    },
    fetchAcdmActiveFields: (state, action) => {
      state.activeFields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAcdm.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAcdm.fulfilled, (state, action) => {
        state.loading = false;
        state.acdmFlights = action.payload;
      })
      .addCase(fetchAcdm.rejected, (state) => {
        state.loading = false;
      })
      .addCase(outBoundMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(outBoundMessages.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(outBoundMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AcdmActiveFields.pending, (state) => {
        state.loading = true;
      })
      .addCase(AcdmActiveFields.fulfilled, (state, action) => {
        state.loading = false;
        state.activeFields = action.payload;
      })
      .addCase(AcdmActiveFields.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAcdmActiveFields.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAcdmActiveFields.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateAcdmActiveFields.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { reinitAcdm, updateAcdmSocket, fetchAcdmActiveFields } =
  acdmSlice.actions;

export default acdmSlice.reducer;
