import React from "react";
import { useDispatch } from "react-redux";
import { logOut } from "features/keycloakActions";
import { Link, useLocation } from "react-router-dom";
import { REINIT_USER } from "redux/constants";

const UserGrid = ({ handleUserSelection, display, user, auth }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const logOutUser = () => {
    dispatch({ type: REINIT_USER });
    dispatch(logOut());
    handleUserSelection();
  };

  return (
    <div className={display ? "user-grid show" : "user-grid"}>
      <div className="user-grid-select-header">Welcome {user?.name}</div>
      <div className="user-grid-bar">
        {location.pathname !== "/me/account" && (
          <Link
            to="/me/general"
            className="selection profile-link"
            onClick={handleUserSelection}
          >
            Profile
          </Link>
        )}
        {auth?.user.roles[0]["responsible_for_tobt"] && location.pathname !== "/me/account"? (
          <Link
            to="/me/select-flights"
            onClick={handleUserSelection}
            className="selection profile-link"
          >
            Select Flights
          </Link>
        ) : null}
        <Link
          to="/me/account"
          className="selection profile-link"
          onClick={handleUserSelection}
        >
          Select Role
        </Link>
        <div
          title="Log out"
          className="selection"
          tabIndex="0"
          role="button"
          onKeyDown={(ev) => {
            if (ev.keyCode === 13) {
              ev.target.click();
            }
          }}
          onClick={logOutUser}
        >
          Log out
        </div>
      </div>
    </div>
  );
};

export default UserGrid;
