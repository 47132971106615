import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import store from "./store";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./app";
import { AuthContexProvider } from "context/authWidget";

const theme = createTheme({
  typography: {
    fontFamily: "Proxima Nova",
  },
});

render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <div className="app-root">
              <AuthContexProvider>
                <App />
              </AuthContexProvider>
            </div>
            <CssBaseline />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,

  document.getElementById("root"),
);
